* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.main_section {
  position: relative;
}

.main__section {
  width: 100%;
  display: flex;
  width: 100%;
  transition: 0.3s all ease-in;
}
.menu_toggle_btn {
  position: fixed;
  top: 20px;
  left: 20px;
  display: none;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 2px;
  background: #24313f;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.menu_toggle_btn .icon {
  font-size: 24px;
  color: #fff;
}

.menu_close_btn {
  position: absolute;
  z-index: 999;
  top: 20px;
  left: 260px;
  display: none;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  transition: 0.3s all ease-in;
}
.menu_close_btn .icon {
  font-size: 24px;
  color: #fff;
  transition: 0.3s all ease-in;
}

.sidebar_div {
  min-width: 260px;
  transition: 0.3s all ease-in;
  min-height: 100vh;
  background: #24313f;
  height: auto;
}
.SideContent_div {
  width: 100%;
  transition: 0.3s all ease-in;
}

@media (min-width: 1200px) {
  .SideContent_div {
    width: 83%;
    transition: 0.3s all ease-in;
  }
}
.sidebar_div_coll {
  width: 0;
  transition: 0.3s all ease-in;
  height: 100%;
}

.SideContent_div_coll {
  min-width: 100%;
  transition: 0.3s all ease-in;
}

/* ====================================== */
/* ============sidebar=============== */
/* ====================================== */

.dashboard_sidebar_main_div {
  background: #24313f;
  /* width: 320px; */
  min-height: 100vh;
  height: 100%;
  height: auto;
  position: relative;
  z-index: 999;
  position: fixed;
  min-width: 260px;
}
.white_logo_div {
  text-align: center;
  padding: 20px 0 20px 0;
}
.white_logo {
  width: 80px;
}
.sidebar_link {
  padding: 12px;
  padding-left: 35px;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  display: block;
  letter-spacing: 1px;
  height: 52px;
}

.sidebar_link_icon {
  padding-right: 18px;
  width: 48px;
}

.sidebar_link:hover {
  text-decoration: none;
  color: #fff;
}

.active_link {
  border-radius: 0px 5px 5px 0px;
  background: #0848af;
  box-shadow: 0px 12px 20px 0px #0a3882;
}
.header_main_div .active{
  background-color: #236BDF;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_right_side_div_nav_link{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_right_side_div_nav{
  display: flex;
}
.signout_btn_div {
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.signout_btn_div a {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent !important;
  box-shadow: none !important;
  letter-spacing: 1px;
  /* padding: 12px; */
  height: 52px;
}
.dashboard_sidebar_main_div .active {
  border-radius: 0px 5px 5px 0px;
  background: #0848af;
  box-shadow: 0px 6px 5px 0px #0a3882;
  font-weight: 600;
}
/* ====================================== */
/* ============Header=============== */
/* ====================================== */

.header_main_div {
  height: 60px;
  min-width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 28px;
  border-bottom: 1px solid #6e6e6e;
  position: sticky;
  top: 0;
  z-index: 9;
}
.header_search_box {
  display: flex;
  align-items: center;
}
.header_search_box img {
  max-width: 17px;
}
.header_search_box input {
  color: #6e6e6e;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  width: 100%;
  margin-left: 10px;
  border: 1px solid transparent;
  outline: none;
  padding: 2px 10px;
  border-radius: 5px;
  min-width: 300px;
  letter-spacing: 1px;
}
.header_search_box input:focus {
  border: 1px solid #24313f;
}
.header_search_box input::placeholder {
  color: #6e6e6e;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.header_right_side_div {
  background: #24313f;
  padding: 16px 0 16px 16px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.border_right {
  border-right: 1px solid #fff;
  /* height: 41px; */
  /* width: 41px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 14px;
  padding-left: 14px;
}
.border_right a img {
  min-width: 28px;
}
.header_profile_btn {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_profile_btn img {
  border: 1px solid #fff;
  padding: 3px;
  border-radius: 50%;
  min-width: 48px;
  min-height: 48px;
  width: 48px;
  height: 48px;
}
.header_profile_btn a {
  margin-bottom: 0;
  padding-bottom: 0;
}
.header_profile_btn a span {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding-left: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
  letter-spacing: 1px;
  margin-right: 28px;
  text-transform: capitalize;
}

.mt_12 {
  margin-top: 12px;
}

.header_pos_rel{
  position: relative;
}
.header_badge{
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  background-color: #E5ECF6;
  height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    color: #777;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
}