.chat_main_section {
  /* background-color: #003441; */

  display: flex;
}

.chat_side_list_div {
  width: 30%;
  /* min-width: 50%; */
  min-height: 540px;
  max-height: 540px;
  overflow-y: auto;
  border-radius: 8px;
  background: #d9e9fe;
  padding: 16px;
  height: 100%;
  transition: 0.3s all ease-in-out;
}
.chat_msg_side {
  width: 70%;
  position: relative;
  margin-left: 20px;
  transition: 0.3s all ease-in-out;

  border-radius: 8px;
  border: 1px solid #ebebeb;
  background: #f9f9f9;
}
/* width */
.custom_scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.custom_scroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}

/* Handle */
.custom_scroll::-webkit-scrollbar-thumb {
  background: #535353;
  border-radius: 10px;
}

/* Handle on hover */
.custom_scroll::-webkit-scrollbar-thumb:hover {
  background: #003441;
}
.chat_main_section .small_box {
  width: 0%;
  display: none;
  transition: 0.3s all ease-in-out;
}
.chat_main_section .big_box {
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.user_single_chat_list {
  padding: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 16px;
}
.user_single_chat_list_inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user_single_chat_list_inner img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
}
.user_single_chat_list h3 {
  color: #5c5c5c;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.167px;
  margin-bottom: 8px;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.user_single_chat_list h4 {
  color: #777;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.user_single_chat_list .active {
  color: #21ba62;
}
.msg_number {
  color: #777 !important;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
}

.chat_msg_side_main {
  background: #f9f9f9;

  /* min-height: 500px;
  max-height: 500px; */
  overflow-y: auto;
}
.chat_msg_main_div {
  padding: 10px 10px;
  margin: 80px 0;
  min-height: 380px;
  max-height: 380px;
  overflow-y: auto;
}

.chat_msg {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.chat_msg p {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 22px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  color: #5c5c5c;
}
.right_side_msg {
  max-width: 50%;
  width: fit-content;
  margin-right: auto;
  background-color: #fff;
}
.left_side_msg {
  max-width: 50%;
  width: fit-content;
  margin-left: auto;
  background-color: #0848af;
  color: #fff;
}
.left_side_msg p {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 22px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  color: #fff;
}
.msg_time_date {
  font-size: 12px;
  display: block;
  margin-top: 10px;
  text-align: right;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  color: #777;
}
.left_side_msg .msg_time_date {
  color: #fff;
}
.chat_box_search input {
  box-shadow: none;
  outline: none;
  padding: 12px 8px 12px 45px;
  width: 100%;
  border-radius: 35.806px;
  border: 1.194px solid #ebebeb;
  background: #fff;
  color: #a7a7a7;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
}
.chat_box_search input::placeholder {
  color: #a7a7a7;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
}

.chat_box_search input:focus {
  border: 1px solid #777;
  outline: none;
}
.chat_box_search {
  margin-bottom: 28px;
  position: relative;
}
.chat_box_search img {
  position: absolute;
  left: 5%;
  top: 50%;
  transform: translate(0, -50%);
  width: 16px;
}
.chat_container_top_bar {
  /* background-color: #d9e9fe; */
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #5c5c5c;
}

.chat_container_top_bar button {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.chat_container_top_bar button .icon {
  color: #24313f;
  font-size: 24px;
  font-weight: 600;
}

.profile_icon {
  font-size: 26px;
  color: #fff;
  margin-right: 10px;
}
.profile_icon:hover {
  text-transform: capitalize;
  color: #fff;
}

.msg_container_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 12px 16px 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.msg_container_footer_inner {
  display: flex;
  width: 100%;
}
.msg_type_input_box {
  width: 100%;
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.msg_type_input_box input {
  width: 100%;
  border: 1px solid #ffffff;
  padding: 12px;
  box-shadow: none;
  color: #000;
  background-color: transparent;
  border-radius: 5px;

  border-radius: 9.548px;
  border: 1.194px solid rgba(0, 0, 0, 0.12);
  background: #fff;

  color: #777;
  font-family: 'Roboto', sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
}

.msg_type_input_box input::placeholder {
  color: #777;
  font-family: 'Roboto', sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
}
.msg_type_input_box input:focus {
  outline: none;
  border: 1px solid #777;
}
.msg_type_input_box button {
  position: absolute;
  right: 1%;
  top: 50%;
  transform: translate(0, -50%);
  box-shadow: none;
  border: none;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
}

.attachment_file_btn {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}
.send_btn {
  /* background-color: red; */
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.send_btn button {
  background-color: transparent;
  border: none;
}
.send_btn .icon {
  font-size: 22px;
  color: #fff;
}
.chat_side_container_main_user {
  margin-left: 20px;
}
.chat_side_container_main_user h3 {
  color: #5c5c5c;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  margin-bottom: 4px;
  text-transform: capitalize;
}
.chat_side_container_main_user h4 {
  color: #777;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
}
.chat_side_container_main_user .active {
  color: #21ba62;
}
.chat_side_container_main_user img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
}

.char_starting_screen{
    position: relative;
    transition: 0.3s all ease-in-out;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    background: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.char_starting_screen img{
  max-width: 30%;
}
@media screen and (max-width: 991px) {
  .chat_side_list_div {
    width: 44%;
    padding: 8px;
  }
  .user_single_chat_list h3,
  .chat_side_container_main_user h3 {
    font-size: 12px;
  }
  .chat_side_container_main_user .active {
    font-size: 12px;
    font-weight: 400;
  }
  .chat_container_top_bar {
    padding: 8px;
  }
  .user_single_chat_list_inner img {
    width: 24px;
    height: 24px;
  }
  .chat_msg_side {
    margin-left: 12px;
  }
  .chat_msg p {
    font-size: 12px;
    line-height: 18px;
  }
  .right_side_msg,
  .left_side_msg {
    max-width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .user_single_chat_list {
    padding: 5px;
  }
}

@media screen and (max-width: 767px) {
  .chat_side_list_div {
    position: absolute;
    width: 85% !important;
    display: none;
    left: 0;
    z-index: 9;
    height: 100%;
  }
  .chat_main_section .small_box {
    display: block;
  }
  .chat_msg_side {
    width: 100%;
  }
  .chat_main_section .card .card-body {
    padding: 0;
  }
  .chat_container_top_bar button .icon {
    position: relative;
    z-index: 99;
  }
  .chat_box_search {
    margin-top: 40px;
  }
  .right_side_msg,
  .left_side_msg {
    max-width: 100%;
  }
  .content-wrapper {
    padding: 10px;
  }
  .chat_msg_side {
    margin-left: 0px;
  }
  .chat_side_list_div {
    padding: 12px;
  }
  .user_single_chat_list {
    padding: 8px;
  }
  .user_single_chat_list h3 {
    margin-bottom: 4px;
  }
}
